<template>
  <ngid-base-layout class="subscription-update" fluid>
    <v-breadcrumbs class="pa-0 mt-n6" :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <h1 class="mt-4">{{ $t('subscription.titleChange') }}</h1>
    <div class="subscription-plans plan-choice-wrapper" v-if="isDataLoaded">
      <div class="plan-choice">
        <KeepAlive>
          <SubscriptionPlans
            v-if="!selectedSubscription"
            @selectSubscription="setSelectedSubscription"
          />
        </KeepAlive>
        <SubscriptionCheckout
          v-if="selectedSubscription"
          :selected-plan="selectedSubscription.plan"
          :selected-period="selectedSubscription.period"
          @cancel="resetSubscriptionSelection"
        />
      </div>
    </div>

    <v-row v-else>
      <v-col lg="8">
        <div class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <slot></slot>
  </ngid-base-layout>
</template>

<script>
import NgidBaseLayout from '@/layouts/NgidBaseLayout';
import SubscriptionPlans from '../components/subscription/SubscriptionPlans.vue';
import SubscriptionCheckout from '../components/subscription/SubscriptionCheckout.vue';
import * as subscriptionService from '@/services/subscriptionService';
import { mapState, mapGetters } from 'vuex';

const url = new URL(location.href);

export default {
  components: {
    NgidBaseLayout,
    SubscriptionPlans,
    SubscriptionCheckout,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('subscription.title'),
          href: '/subscription',
        },
        {
          text: this.$t('subscription.titleChange'),
        },
      ],
      selectedSubscription: undefined,
      routeParams: {
        plan: url.searchParams.get('plan'),
        period: url.searchParams.get('period'),
      },
    };
  },
  computed: {
    ...mapState('subscription', ['isDataLoaded']),
    ...mapGetters('subscription', ['availablePlansMap']),
  },
  watch: {
    availablePlansMap: {
      handler(value) {
        if (
          value &&
          this.routeParams.plan &&
          this.routeParams.period &&
          value[this.routeParams.plan]
        )
          this.setSelectedSubscription({
            plan: this.availablePlansMap[this.routeParams.plan],
            period: this.routeParams.period,
          });
      },
      once: true,
    },
  },
  async created() {
    if (!this.availablePlansMap) await subscriptionService.loadSubscriptionSettings();
  },
  methods: {
    setSelectedSubscription(subscription) {
      this.selectedSubscription = subscription;
    },
    resetSubscriptionSelection() {
      this.setSelectedSubscription(undefined);
      url.searchParams.delete('plan');
      url.searchParams.delete('period');
      history.pushState(null, '', url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
