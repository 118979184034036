import httpClient from './httpClient';

const token_query = !!process.env.VUE_APP_REPKA_TOKEN
  ? `dev_repka_token=${process.env.VUE_APP_REPKA_TOKEN}`
  : ' ';

const addDownloadLinksToReleases = (releases) =>
  releases.map((release) => {
    if (release.files && release.files.length > 0) {
      const releaseFilesWithLinks = release.files.map((releaseFile) => ({
        ...releaseFile,
        link: `${process.env.VUE_APP_API_URL}v1/releases/${releaseFile.id}/download?save_as=${releaseFile.name}&${token_query}`,
      }));
      return {
        ...release,
        files: releaseFilesWithLinks,
      };
    }
    return release;
  });

const getReleases = () => {
  return httpClient
    .get(`v1/releases?tag=latest&option=type:stable&${token_query}`)
    .then((resp) => addDownloadLinksToReleases(resp.data));
};

export { getReleases };
