import YookassaPaymentGateway from './YookassaPaymentGateway';
import StripePaymentGateway from './StripePaymentGateway';
import store from '@/store';
import * as paymentApi from '@/api/paymentApi';
const PAYMENT_CHEKCING_TIME_LIMIT = 40 * 1000;

const createPaymentGateway = (paymentGatewayId) =>
  paymentGatewayId === 'yookassa' ? new YookassaPaymentGateway() : new StripePaymentGateway();

const getAutopayment = () => paymentApi.getAutopayment();

const createPayment = ({ provider, plan, interval, userData }) =>
  paymentApi.createAutopayment({ provider, plan, interval, userData });

const updateAutopayment = ({ plan, interval, userData }) =>
  paymentApi.updateAutopayment({ plan, interval, userData });

const deleteAutopayment = () => paymentApi.deleteAutopayment();

const getAutopaymentStatus = async () => {
  try {
    const { status, latest_error } = await getAutopayment();
    return { status, latest_error };
  } catch (error) {
    if (error.errors[0].status !== 404) throw error;
    return null;
  }
};

const updateAutopaymentStatus = (newStatus) => paymentApi.updateAutopaymentStatus(newStatus);

const checkAutopaymentStatusUntilResolved = () => {
  const isStatusTerminated = (status) => ['canceled', 'active', 'failed', null].includes(status);

  let autopaymentStatus = null;
  let autopaymentLastError = null;

  return new Promise((resolve) => {
    store.commit('payments/SET_IS_WAITING_DATA_FROM_PAYMENT_GATEWAY', true);
    const resolveChekingAutopaymentPromise = ({ status, latest_error }) => {
      store.commit('payments/SET_IS_WAITING_DATA_FROM_PAYMENT_GATEWAY', false);
      resolve({ status, latest_error });
    };
    getAutopaymentStatus().then(({ status, latest_error }) => {
      if (isStatusTerminated(status)) {
        resolveChekingAutopaymentPromise({ status, latest_error });
        return;
      }
      autopaymentStatus = status;
      autopaymentLastError = latest_error;

      const checkingInterval = setInterval(async () => {
        getAutopaymentStatus().then(({ status, latest_error }) => {
          if (isStatusTerminated(status)) {
            clearInterval(checkingInterval);
            resolveChekingAutopaymentPromise({ status, latest_error });
            return;
          }
          autopaymentStatus = status;
          autopaymentLastError = latest_error;
        });
      }, 3000);

      setTimeout(() => {
        clearInterval(checkingInterval);
        resolveChekingAutopaymentPromise({
          status: autopaymentStatus,
          latest_error: autopaymentLastError,
        });
      }, PAYMENT_CHEKCING_TIME_LIMIT);
    });
  });
};

export {
  getAutopayment,
  createPaymentGateway,
  createPayment,
  updateAutopayment,
  deleteAutopayment,
  checkAutopaymentStatusUntilResolved,
  updateAutopaymentStatus,
};
